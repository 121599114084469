// src/components/Layout.js
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../App.css";
import EditSettings from "./EditSettings";

function Home({ children }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    window.dispatchEvent(new Event("authChange"));
    navigate("/login");
  };

  return (
    <div className="layout">
      <header className="top-banner">
        <h1 className="banner-title">CiciDoktorum Panel</h1>
      </header>

      <div className="sidebar">
        <nav>
          <ul>
            {
              <li>
                <NavLink to="/user" activeClassName="active">
                  Kullanıcılar
                </NavLink>
              </li>
              /* <li>
              <NavLink to="/doctor" activeClassName="active">
                Doctor Module
              </NavLink>
            </li>
            <li>
              <NavLink to="/section" activeClassName="active">
                Section Module
              </NavLink>
            </li>
            <li>
              <NavLink to="/appointment" activeClassName="active">
                Appointment Module
              </NavLink>
            </li> */
            }
            <li>
              <NavLink to="/settings" activeClassName="active">
                Ayarlar
              </NavLink>
            </li>
          </ul>
          <button className="logout-button" onClick={handleLogout}>
            Çıkış
          </button>
        </nav>
      </div>

      <main className="content-area">{children}</main>
    </div>
  );
}

export default Home;
