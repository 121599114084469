// src/pages/Appointment.js
import React from "react";
import Home from "./Home";

function Appointment() {
  return (
    <Home>
      <h2>Appointment Page</h2>
      <p>Here you can configure your settings.</p>
    </Home>
  );
}

export default Appointment;
