// src/utils/useAuth.js
import { useState, useEffect } from "react";

function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("access_token")
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setIsAuthenticated(!!localStorage.getItem("access_token"));
    };

    // Listen for storage changes (e.g., in other tabs)
    window.addEventListener("storage", handleStorageChange);

    // Optionally, listen for custom events if authentication changes within the same tab
    const handleAuthChange = () => {
      handleStorageChange();
    };
    window.addEventListener("authChange", handleAuthChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("authChange", handleAuthChange);
    };
  }, []);

  return isAuthenticated;
}

export default useAuth;
