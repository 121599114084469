// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Settings from "./pages/Settings"; // Import new pages
import useAuth from "./utils/useAuth"; // Hook to check if user is authenticated
import Doctor from "./pages/Doctor";
import User from "./pages/User";
import Section from "./pages/Section";
import Appointment from "./pages/Appointment";
import EditSettings from "./pages/EditSettings";

function App() {
  const isAuthenticated = useAuth(); // Check if user is authenticated

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/home" /> : <Login />}
        />
        <Route
          path="/home"
          element={isAuthenticated ? <Home /> : <Navigate to="/login" />}
        />
        <Route
          path="/settings"
          element={isAuthenticated ? <Settings /> : <Navigate to="/login" />}
        />
        <Route
          path="/doctor"
          element={isAuthenticated ? <Doctor /> : <Navigate to="/doctor" />}
        />
        <Route
          path="/user"
          element={isAuthenticated ? <User /> : <Navigate to="/user" />}
        />
        <Route
          path="/section"
          element={isAuthenticated ? <Section /> : <Navigate to="/section" />}
        />
        <Route
          path="/appointment"
          element={
            isAuthenticated ? <Appointment /> : <Navigate to="/appointment" />
          }
        />
        <Route
          path="/"
          element={<Navigate to={isAuthenticated ? "/settings" : "/login"} />}
        />
        <Route path="/settings/edit/:key" element={<EditSettings />} />
      </Routes>
    </Router>
  );
}

export default App;
