import React from "react";
import "./ErrorModal.css";

function ErrorModal({ error, visible }) {
  return (
    error && (
      <div className={`error-modal ${visible ? "show" : "hide"}`}>
        <p>{error}</p>
      </div>
    )
  );
}

export default ErrorModal;
