import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { API_URL, API_KEY } from "../constants";
import ErrorModal from "../components/ErrorModal";
import Home from "./Home"; // Reuse the Home layout for the sidebar and top banner

function EditSettings() {
  const { key } = useParams(); // Fetch key from URL parameters
  const location = useLocation();
  const initialValue = location.state?.value || ""; // Get value from location state or set default
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(null);
  const [errorVisible, setErrorVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getKeyDescription = (key) => {
    switch (key) {
      case "termsofuse":
        return "Kullanım Şartları";
      case "membership":
        return "Gizlilik Sözleşmesi";
      case "kvkk":
        return "Kişisel Verilerin Korunma Sözleşmesi";
      default:
        return key; // Default description for unknown keys
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setErrorVisible(false);

    try {
      const response = await fetch(`${API_URL}/updateSettings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
          key: key,
          value: value,
        }),
      });

      const data = await response.json();

      console.log(data);

      if (data.status === "success") {
        navigate("/settings");
      } else {
        setError("Güncelleme başarısız oldu, lütfen tekrar deneyin.");
        setErrorVisible(true);
        setTimeout(() => {
          setErrorVisible(false);
        }, 1500);
      }
    } catch (error) {
      setError("Güncelleme sırasında bir hata oluştu.");
      setErrorVisible(true);
      setTimeout(() => {
        setErrorVisible(false);
      }, 1500);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue(initialValue); // Set the initial value
  }, [initialValue]);

  return (
    <Home>
      <div className="edit-settings-page">
        <header className="settings-header">
          <button onClick={() => navigate(-1)} className="back-button">
            ← {/* Left arrow symbol */}
          </button>
          <h2>{getKeyDescription(key)}</h2>
        </header>
        <main>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="value">Tanım</label>
              <textarea
                id="value"
                className="form-control"
                rows="5"
                style={{ height: "300px" }}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                required
              />
            </div>
            <div className="form-actions">
              <button type="submit" className="save-button" disabled={loading}>
                {loading ? "Kaydediliyor..." : "Kaydet"}
              </button>
            </div>
          </form>
          <ErrorModal error={error} visible={errorVisible} />
        </main>
      </div>
    </Home>
  );
}

export default EditSettings;
