// src/pages/User.js
import React from "react";
import Home from "./Home";

function User() {
  return (
    <Home>
      <h2>User Page</h2>
      <p>Here you can configure your settings.</p>
    </Home>
  );
}

export default User;
