// src/pages/Section.js
import React from "react";
import Home from "./Home";

function Section() {
  return (
    <Home>
      <h2>Section Page</h2>
      <p>Here you can configure your settings.</p>
    </Home>
  );
}

export default Section;
