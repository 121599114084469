// src/pages/Settings.js
import React, { useState, useEffect } from "react";
import { API_KEY, API_URL } from "../constants";
import ErrorModal from "../components/ErrorModal"; // Import error modal component
import Home from "./Home"; // Import Home component
import "../App.css"; // Import global styles
import { NavLink } from "react-router-dom";

function Settings() {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorVisible, setErrorVisible] = useState(false);

  const fetchSettings = async () => {
    setLoading(true);
    setError(null);
    setErrorVisible(false);

    try {
      const response = await fetch(`${API_URL}/listSettings`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
        },
      });

      const data = await response.json();

      if (data.status === "success") {
        setSettings(data.data);
      } else {
        setError("Ayarlar alınamadı, lütfen tekrar deneyiniz.");
        setErrorVisible(true);
        setTimeout(() => {
          setErrorVisible(false);
        }, 1500);
      }
    } catch (error) {
      setError("Ayarlar alınamadı, lütfen tekrar deneyiniz.");
      setErrorVisible(true);
      setTimeout(() => {
        setErrorVisible(false);
      }, 1500);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const getKeyDescription = (key) => {
    switch (key) {
      case "termsofuse":
        return "Kullanım Şartları";
      case "membership":
        return "Gizlilik Sözleşmesi";
      case "kvkk":
        return "Kişisel Verilerin Korunma Sözleşmesi";
      default:
        return key;
    }
  };

  return (
    <Home>
      <div className="settings-page">
        <header className="settings-header">
          <h2>Ayarlar</h2>
          <button className="refresh-button" onClick={fetchSettings}>
            🔄
          </button>
        </header>
        <main>
          {loading ? (
            <span className="spinner"></span>
          ) : (
            <table className="settings-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Tanım</th>
                  <th>Güncelle</th>
                </tr>
              </thead>
              <tbody>
                {settings.map((setting) => (
                  <tr key={setting.id}>
                    <td>{setting.id}</td>
                    <td>{getKeyDescription(setting.key)}</td>{" "}
                    <NavLink
                      to={`/settings/edit/${setting.key}`}
                      state={{ value: setting.value }}
                    >
                      <button className="edit-button">Güncelle</button>
                    </NavLink>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <ErrorModal error={error} visible={errorVisible} />
        </main>
      </div>
    </Home>
  );
}

export default Settings;
