// src/pages/Login.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Updated import
import ErrorModal from "../components/ErrorModal"; // Error modal component
import "../App.css"; // Import global styles including spinner and error modal
import { API_KEY, API_URL } from "../constants";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const navigate = useNavigate(); // Use useNavigate for redirection

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    setErrorVisible(false);

    try {
      const response = await fetch(`${API_URL}/adminLogin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (data.status === "success") {
        localStorage.setItem("access_token", data.data.access_token);
        window.dispatchEvent(new Event("authChange")); // Notify auth change
        navigate("/settings");
      } else {
        setError("Giriş başarısız, lütfen tekrar deneyiniz");
        setErrorVisible(true);
        setTimeout(() => {
          setErrorVisible(false);
        }, 1500);
      }
    } catch (error) {
      setError("Giriş başarısız, lütfen tekrar deneyiniz");
      setErrorVisible(true);
      setTimeout(() => {
        setErrorVisible(false);
      }, 1500);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Cicidoktorum</h1>
      </header>
      <main className="App-main">
        <form onSubmit={handleLogin} className="login-form">
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              style={{ marginTop: "10px" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Şifre:</label>
            <input
              type="password"
              id="password"
              style={{ marginTop: "10px" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? <span className="spinner"></span> : "Giriş"}
          </button>
        </form>
        <ErrorModal error={error} visible={errorVisible} />
      </main>
    </div>
  );
}

export default Login;
