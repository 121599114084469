// src/pages/Doctor.js
import React from "react";
import Home from "./Home";

function Doctor() {
  return (
    <Home>
      <h2>Doctor Page</h2>
      <p>Here you can configure your settings.</p>
    </Home>
  );
}

export default Doctor;
